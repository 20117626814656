<template>
  <nav class="navbar navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img alt="" height="35" src="@/assets/logo.png" width="35">
        Secure Share
      </a>
    </div>
  </nav>
</template>

<script setup>
</script>

<style scoped>
img {
  border-radius: 8px;
}
</style>