<template>
  <div class="container bg-light">
    <p>
      Created by Michael Hainz <i class="bi bi-person-square"></i>
    </p>
  </div>
</template>

<script setup>
</script>

<style scoped>
.container{
  position: fixed;
  width: 100vw;
  max-width: unset;
  bottom: 0;
}
.container p {
  width: 100vw;
  margin: 0.8rem;
}
</style>