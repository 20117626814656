<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <QRCode :content="keys.public"/>
      </div>
      <div class="col">
        <p>How do I use this website?</p>
        <ol class="list-group list-group-numbered">
          <li class="list-group-item">Scan this QR Code with your smartphone <i class="bi bi-phone"></i></li>
          <li class="list-group-item">On your smartphone, enter your secret <i class="bi bi-lock"></i></li>
          <li class="list-group-item">On your Browser, copy your secret <i class="bi bi-unlock"></i></li>
        </ol>
      </div>
    </div>
    <div id="secret">
      <div class="mb-3">
        <h2>
          Your secret will be shown below
        </h2>
        <div class="input-group">
          <input v-model="secret.decryptedContent" :type="secret.show ? 'text' : 'password'"
                 aria-label="Super secure password!"
                 class="form-control"
                 placeholder="Super secure password!" readonly>
          <button class="btn btn-outline-secondary" type="button" @click="secret.show = !secret.show">
            {{ secret.show ? 'Hide' : 'Show' }} <i :class="secret.show ? 'bi-eye-slash' : 'bi-eye'" class="bi"></i>
          </button>
          <button class="btn btn-success" type="button"
                  @click="copy()">
            Copy <i class="bi bi-clipboard"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import QRCode from '@/components/QRCode.vue';
import JSEncrypt from 'jsencrypt';
import {reactive} from "vue";
import {HOST} from "@/main";
import md5 from 'md5';

const crypt = new JSEncrypt();
const keys = reactive({
  public: crypt.getPublicKey(),
});
const secret = reactive({
  encryptedContent: "",
  decryptedContent: "",
  show: false,
});
let id = md5(keys.public);

function decrypt() {
  let decrypted = crypt.decrypt(secret.encryptedContent);
  secret.decryptedContent = decrypted;
}

function createWebSocket(id) {
  const ws = new WebSocket("wss://" + HOST + "/secure-share-api/" + id);

  ws.onmessage = (data) => {
    secret.encryptedContent = data.data;
    decrypt();
  }
}

function copy() {
  navigator.clipboard.writeText(secret.decryptedContent)
}

setTimeout(() => createWebSocket(id), 10);
</script>

<style scoped>
.container {
  width: 60rem;
  margin-top: 2rem;
}

.col p {
  margin-top: 2rem;
}

#secret {
  margin-top: 5rem;
}
</style>