<template>
  <canvas id="canvas"></canvas>
</template>

<script setup>
import QRCode from 'qrcode';

// eslint-disable-next-line no-undef
const props = defineProps({
  content: String,
});

setTimeout(() => {
  const canvas = document.getElementById('canvas');

  QRCode.toCanvas(canvas, props.content, function (error) {
    if (error) console.error(error)
  })
},1);

</script>

<style scoped>

</style>
